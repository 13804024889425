
export enum Permission {
  AdministreraAllaAnvändare = "FleetApi.AdministreraAllaAnvändare",
  AdministreraAssistansbolag = "FleetApi.AdministreraAssistansbolag",
  AdministreraAvrop = "FleetApi.AdministreraAvrop",
  AdministreraExternaKörjournalsrapporter = "FleetApi.AdministreraExternaKörjournalsrapporter",
  AdministreraFöretagsanvändare = "FleetApi.AdministreraFöretagsanvändare",
  AdministreraInformationOchBlanketter = "FleetApi.AdministreraInformationOchBlanketter",
  AdministreraInternaAnvändare = "FleetApi.AdministreraInternaAnvändare",
  AdministreraNyheter = "FleetApi.AdministreraNyheter",
  AdministreraSamarbetspartners = "FleetApi.AdministreraSamarbetspartners",
  AdministreraStatusExternKörjournalsrapportering = "FleetApi.AdministreraStatusExternKörjournalsrapportering",
  AdministreraVanligaFrågor = "FleetApi.AdministreraVanligaFrågor",
  FleetDashboard = "FleetApi.FleetDashboard",
  ImpersonateLogin = "ImpersonateLogin",
  KilometerRapportering = "FleetApi.KilometerRapportering",
  LäsaBeställDrivmedelskortÄrende = "FleetApi.LäsaBeställDrivmedelskortÄrende",
  LäsaÄndraFöraruppgifterÄrenden = "FleetApi.LäsaÄndraFöraruppgifterÄrenden",
  LäsaEgnaFordon = "FleetApi.LäsaEgnaFordon",
  LäsaFakturor = "FleetApi.LäsaFakturor",
  LäsaFöretagsbilarSomLöperUt = "FleetApi.LäsaFöretagsbilarSomLöperUt",
  LäsaInformationOchBlanketter = "FleetApi.LäsaInformationOchBlanketter",
  LäsaInnehållRiktatTillAssistantFleetManagers = "FleetApi.LäsaInnehållRiktatTillAssistantFleetManagers",
  LäsaInnehållRiktatTillFleetManagers = "FleetApi.LäsaInnehållRiktatTillFleetManagers",
  LäsaInnehållRiktatTillFörare ="FleetApi.LäsaInnehållRiktatTillFörare",
  LäsaInnehållRiktatTillLöneadministration ="FleetApi.LäsaInnehållRiktatTillLöneadministration",
  LäsaInnehållRiktatTillRedovisning = "FleetApi.LäsaInnehållRiktatTillRedovisning",
  LäsaKonteringsunderlag = "FleetApi.LäsaKonteringsunderlag",
  LäsaNyheter = "FleetApi.LäsaNyheter",
  LäsaPåminnelser = "FleetApi.LäsaPåminnelser",
  LäsaÄndraBilkategoriÄrende = "FleetApi.LäsaÄndraBilkategoriÄrende",
  LäsaÄndraFörareÄrende = "FleetApi.LäsaÄndraFörareÄrende",
  LäsaÄndraKostnadsställeÄrende = "FleetApi.LäsaÄndraKostnadsställeÄrende",
  MätarställningRapportering = "FleetApi.MätarställningRapportering",
  MätarställningRapporteringFöretag = "FleetApi.MätarställningRapporteringFöretag",
  RapportBruttolöneavdrag = "FleetApi.RapportBruttolöneavdrag",
  Rapporteringshistorik = "FleetApi.Rapporteringshistorik",
  RapportFörmånsvärdesammanställning = "FleetApi.RapportFörmånsvärdesammanställning",
  RapportKostnadsöversiktFörare = "FleetApi.RapportKostnadsöversiktFörare",
  RapportKostnadsöversiktFöretag = "FleetApi.RapportKostnadsöversiktFöretag",
  RapportLöneunderlag = "FleetApi.RapportLöneunderlag",
  RapportMiljörapport = "FleetApi.RapportMiljörapport",
  RapportRapporteradKörsträcka = "FleetApi.RapportRapporteradKörsträcka",
  RapportSplittberäkningFörare = "FleetApi.RapportSplittberäkningFörare",
  RapportTotalaKostnader = "FleetApi.RapportTotalaKostnader",
  RapportTransaktionslista = "FleetApi.RapportTransaktionslista",
  RapportVagnsparksöversikt = "FleetApi.RapportVagnsparksöversikt",
  SigneraAvrop = "FleetApi.SigneraAvrop",
  SkapaBeställDrivmedelskortÄrende = "FleetApi.SkapaBeställDrivmedelskortÄrende",
  SkapaÄndraBilkategoriÄrende = "FleetApi.SkapaÄndraBilkategoriÄrende",
  SkapaÄndraFörareÄrende = "FleetApi.SkapaÄndraFörareÄrende",
  SkapaÄndraFöraruppgifterÄrendeEndastAnställningsnummer = "FleetApi.SkapaÄndraFöraruppgifterÄrendeEndastAnställningsnummer",
  SkapaÄndraFöraruppgifterÄrendeAllaUppgifter = "FleetApi.SkapaÄndraFöraruppgifterÄrendeAllaUppgifter",
  SkapaÄndraKostnadsställeÄrende = "FleetApi.SkapaÄndraKostnadsställeÄrende",
  TrängselskattRapportering = "FleetApi.TrängselskattRapportering",
  Vagnparksöversikt = "FleetApi.Vagnparksöversikt"
}