import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, Observable, throwError, EMPTY } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { LoggingService } from '@core/services/logging.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private _router: Router,
      private _authenticationService: AuthenticationService,
      private _loggingService: LoggingService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(
        catchError(error => {

          if (error.status === 401) {
            // auto logout if 401 Unauthorized
            if (request.url.endsWith(AuthenticationService.LogoutUrl)) {
              return EMPTY;
            }

            this._authenticationService.logout().subscribe({
              next: () => {
                this._router.navigate(["/login"]); 
              },
              error: (error) => {
                this._loggingService.logError("ErrorInterceptor failed to log out.", error);
                this._router.navigate(["/login"]);
              },
              complete: () => {
        
              }
            });
          }
          //const error = error.error.message || err.statusText;
          return throwError(() => error);
        })
      );
    }
}
