import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeroService } from '../../../domain/services/hero.service';
import { HeroModel } from '../../../domain/models/hero.model';
import { AuthenticationService } from '@core/services/authentication.service';
import { UserModel } from '@core/models/user.model';
import { Router } from '@angular/router';
import { PermissionGroups } from '@core/models/permission-groups';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit, OnDestroy {

  content: HeroModel = new HeroModel();
  defaultImage: string = 'assets/images/bg-img-desktop-hd.jpg';
  user: UserModel;

  private _componentSubscriptions = new Array<Subscription>();

  constructor(private _heroService: HeroService,
    private _authenticationService: AuthenticationService,
    private _router: Router) {
    
  }

  ngOnInit(): void {
    this._componentSubscriptions.push(this._authenticationService.currentUser$.subscribe(u => this.user = u));

    this._componentSubscriptions.push(this._heroService.content$.subscribe(
      content => {
        setTimeout(() => {
          this.content = content;
        },
          0);
      }
    ));
  }

  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
        s.unsubscribe();
    });      
    this._componentSubscriptions.splice(0);  
  }

  get hasSuperUserPermissions() : boolean{
    return this.user && this.user.hasAnyPermission(PermissionGroups.SuperUserPermissions);
  }

  get showImpersonatingInformation (): boolean{
    return this.user.isImpersonating && this.user.validationProblems.length > 0;
  }

  buttonClicked() {
    if (this.content.buttonUrl) {
      this._router.navigateByUrl(this.content.buttonUrl);
    }

    this._heroService.clickEvent.emit();
  }

  secondButtonClicked() {
    if (this.content.secondButtonUrl) {
      this._router.navigateByUrl(this.content.secondButtonUrl);
    }

    this._heroService.secondClickEvent.emit();
  }

  thirdButtonClicked() {
    if (this.content.thirdButtonUrl) {
      this._router.navigateByUrl(this.content.thirdButtonUrl);
    }

    this._heroService.thirdClickEvent.emit();
  }
}
