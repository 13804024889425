import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { BaseApiService } from './services/base-api.service';
import { LoggingService } from './services/logging.service';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { AuthenticationService } from './services/authentication.service';
import { LocalStorageService } from './services/local-storage.service';
import { AuthGuard } from './guards/auth.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HeroComponent } from './components/hero/hero.component';
import { HamburgerMenuComponent } from './components/header/hamburger-menu/hamburger-menu.component';
import { MenuItemComponent } from './components/header/menu-item/menu-item.component';
import { ProfileMenuComponent } from './components/header/profile-menu/profile-menu.component';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { SuperUserMenuComponent } from '@super-user/components/super-user-menu/super-user-menu.component';
import { DialogService } from './services/dialog.service';
import { ScrollService } from './services/scroll.service';


@NgModule({
    declarations: [
        NotFoundComponent,
        FooterComponent,
        HeaderComponent,
        HeroComponent,
        HamburgerMenuComponent,
        MenuItemComponent,
        ProfileMenuComponent,
        LoginComponent,
        ResetPasswordComponent,
        NotAuthorizedComponent,
        SuperUserMenuComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        SharedModule
    ],
    exports: [
        FooterComponent,
        HeaderComponent,
        HeroComponent,
        SuperUserMenuComponent
    ]
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                BaseApiService,
                LoggingService,
                AuthenticationService,
                ScrollService,
                LocalStorageService,
                DialogService,
                AuthGuard,
                { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
            ]
        };
    }
}
