import jwt_decode from "jwt-decode";
import { Actor } from "./actor";
import { PermissionGroups } from "./permission-groups";
import { Permission } from "./permissions.enum";
import { Severity, UserValidationProblem } from "./user-validation-problem.model";

export class UserModel {
  
  constructor(jsonObject: any) {
    this.userName = jsonObject.userName;
    this.firstName = jsonObject.firstName;
    this.lastName = jsonObject.lastName;
    this.email = jsonObject.email;
    this.token = jsonObject.token;
    this.permissions = jsonObject.permissions;
    this.validationProblems = jsonObject.validationProblems.map (v => new UserValidationProblem(v));
  }

  userName: string;
  firstName: string;
  lastName: string;
  token: string;
  email: string;
  permissions: string[];
  validationProblems: Array<UserValidationProblem>;

  get actor(): Actor {
    var actClaim = (jwt_decode(this.token) as any).act;
    var actor = actClaim != null ? new Actor(JSON.parse(actClaim)) : null;

    return actor;
  }

  get isImpersonating(): boolean {
    return this.actor != null;
  }

  get hasSuperUserPermissions() :boolean {
    return this.hasAnyPermission(PermissionGroups.SuperUserPermissions);
  }

  get displayName() : string {
      if (this.firstName && this.lastName) {
        return `${this.firstName} ${this.lastName}`;
      }
      else if (this.firstName) {
        return this.firstName;
      }
      else if (this.lastName) {
        return this.lastName;
      }
      return "";
  }

  hasPermissionStringInput(permission: string): boolean {
    return this.permissions.includes(permission);
  }

  hasPermission(permission: Permission): boolean {
    return this.permissions.includes(permission);
  }

  hasAnyPermission(permissions: Permission[]): boolean {
    return permissions.some(perm => this.permissions.includes(perm));
  }

  hasAllPermissions(permissions: Array<Permission>): boolean {
    return permissions.every(perm => this.permissions.includes(perm));
  }

  get hasPermissions(): boolean {
    return this.permissions.length > 0;
  }

  get hasReportPermissions(): boolean {
    return this.hasAnyPermission(PermissionGroups.ReportPermissions);
  }

  get hasValidationErrors(): boolean {
    return this.validationProblems.some(problem => problem.severity == Severity.Error);
  }
}
